.tba
  text-align: center
  font-size: 40px
  min-height: 53vh
  font-weight: bold
  margin-top: 50px

.publications
  font-size: 16px
  line-height: 24px
  padding: 56px 196px
  .title 
      color: #2A82C4
      font-weight: bold
      font-size: 24px
      margin-bottom: 12px
  .publications_journals
    margin-bottom: 35px
  .publications_conferences
    margin-bottom: 35px
  .publications_invited_speeches
    margin-bottom: 35px

@media (max-width: 1199.98px)
  .tba
    margin-top: 0
    margin: 40px
    font-size: 35px
  .publications
    padding: 20px