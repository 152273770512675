.tba
  text-align: center
  font-size: 40px
  min-height: 53vh
  font-weight: bold
  margin-top: 50px

.deliverables
  display: flex
  background-size: contain
  background-position: bottom left
  background-repeat: no-repeat
  .deliverables_image_wrapper
    width: 50%
    display: flex
    justify-content: flex-end
    .deliverables_image
      width: 488px
      height: 366px
      background-size: contain
      background-position: center
      background-repeat: no-repeat
      margin: 56px 28px
  .deliverables_content_wrapper
    width: 50%
    display: flex
    justify-content: flex-start
    padding: 120px 24px
  .deliverables_content
    width: 488px
    ul 
      list-style: none
      margin-left: -24px
      li
        font-size: 14px
        line-height: 20px
        font-weight: 500
        margin-bottom: 16px
        &:last-child
          margin-bottom: 0
    ul li::before 
      content: "\2022"
      color: #2A82C4
      font-weight: bold
      display: inline-block
      width: 1em
      margin-left: -1em
    .deliverables_content_intro
      color: #2A82C4
    .deliverables_content_details
      padding-top: 16px

@media (max-width: 1199.98px)
  .tba
    margin-top: 0
    margin: 40px
    font-size: 35px
  .deliverables
    flex-direction: column
    .deliverables_image_wrapper
      width: 100%
      display: flex
      justify-content: center
      .deliverables_image
        width: 334px
        height: 250px
        background-size: contain
        background-position: center
        background-repeat: no-repeat
        margin: 20px
    .deliverables_content_wrapper
      width: 100%
      padding: 0
      margin: 20px