.contact
  display: flex
  .contact_team_title
    font-weight: bold
    font-size: 20px
    line-height: 22px
    color: #2A82C4
  .contact_wrapper
    width: 50%
    display: flex
    justify-content: flex-end
    margin: 56px 88px
    img
      width: auto
  .contact_box
    width: 312px
    p
      margin: 0 0 8px
      font-size: 14px
      line-height: 20px
      a
        font-weight: bold
  .japan_wrapper
    justify-content: flex-start

@media (max-width: 1199.98px) 
  .contact
    display: block
    .contact_wrapper
      text-align: center
      display: block
      width: auto
      margin: 24px 20px 12px
    .japan_wrapper
      margin: 24px 20px
    .contact_box
      width: auto