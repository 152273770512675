.footer
  display: flex
  align-item: center
  justify-content: space-between
  padding: 16px 88px
  border-top: 1px solid #e3e3e3
  .copyright
    display: inline-block
    font-size: 14px
    line-height: 20px
    font-weight: 500
    color: #666666
  
  .social_icon a
    margin-right: 20px
    &:last-child
      margin-right: 0

@media (max-width: 1199.98px) 
  .footer
    flex-direction: column
    text-align: center
    .social_icon
      margin-bottom: 16px
      order: 1
    .copyright
      order: 2
      margin-bottom: 12px

  