.architecture
  display: flex
  background-size: contain
  background-position: bottom left
  background-repeat: no-repeat
  .architecture_image_wrapper
    width: 50%
    display: flex
    justify-content: flex-end
    .architecture_image
      width: 486px
      height: 576px
      background-size: contain
      background-position: center
      background-repeat: no-repeat
      margin: 40px 32px
  .architecture_content_wrapper
    text-align: justify
    width: 50%
    padding: 128px 32px 32px
    display: flex
    justify-content: flex-start
  .architecture_content
    width: 524px
    background-size: contain
    background-position: bottom left
    background-repeat: no-repeat
    font-size: 14px
    line-height: 24px
    .architecture_content_intro
      color: #2A82C4
    .architecture_content_details
      ul 
        list-style: none
        margin-left: -24px
        li
          margin-bottom: 16px
          &:last-child
            margin-bottom: 0
      ul li::before 
        content: "\2022"
        color: #2A82C4
        font-weight: bold
        display: inline-block
        width: 1em
        margin-left: -1em

@media (max-width: 1199.98px) 
  .architecture
    flex-direction: column
    padding: 40px 46px
    .architecture_image_wrapper
      width: 100%
      display: flex
      justify-content: center
      .architecture_image
        width: 334px
        height: 334px
        background-size: contain
        background-position: center
        background-repeat: no-repeat
        margin: 0
        margin-bottom: 24px
    .architecture_content_wrapper
      width: 100%
      padding: 0
      .architecture_content
        margin: auto
      
